.interest {
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  transition: box-shadow 0.3s ease;
  cursor: default;
}

.details-container:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #FF77A5;
}

.experience-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  align-items: center;
}

.interest-containers {
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}


/* .about-container {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 4rem;
  align-items: center;
  column-gap: 4rem;
} */


.experience-sub-title {
  color: rgb(85, 85, 85);
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

h2 .icon {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-bottom: 0.8rem;
  color: #FF77A5;
}


.article-container {
  display: flex;
  text-align: initial;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2.5rem;
  gap: 2.5rem;
  justify-content: space-around;
}

article {
  display: flex;
  width: 10rem;
  justify-content: space-around;
  gap: 0.5rem;
}


article .icon {
  height: 2rem;
  align-self: center;
  font-size: 1.5rem;
  /* color: #FF77A5; */
}

article h4 {
  color: rgb(85, 85, 85);
}

article p {
  color: rgb(85, 85, 85);
}

@media screen and (max-width: 1400px) {

  .interest-containers {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 1200px) {

  #experience,
  .experience-details-container {
    margin-top: 2rem;
  }


  .interest-containers {
    margin-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .experience-details-container {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 600px) {

  article {
    font-size: 1rem;
  }

  .interest-containers {
    flex-wrap: wrap;
  }

}