/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&display=swap'); */

/* variables styling */
:root {
  --nav-height: 2rem;

  /* == colors == */
  --hue: 0;
  --sat: 0%;
  --title: hsl(var(--hue), var(--sat), 30%);
  --title-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --white: #FFF;

  /* fs : font size */
  --body-font: "Poppins", sans-serif;
  --large-fs: 3.5rem;
  --normal-fs: 1rem;
  --medium-fs: 0.875rem;
  --small-fs: 0.725rem;
  --tiny-fs: 0.695rem;
  --h1-fs: 1.95rem;
  --h2-fs: 1.5rem;
  --h3-fs: 1.25rem;

  /* fw : font weight */
  --normal-fw: 400;
  --medium-fw: 500;
  --bold-fw: 600;

  /* margin */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1-5: 1.5rem;
  --mb-1: 1rem;
  --mb-2: 2rem;
  --mb-2-25: 2.25rem;
  --mb-3: 3rem;
  --mb-4: 4rem;

  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/*variables for responsive*/
@media screen and (max-width: 992px) {
  :root {
    --large-fs: 2.75rem;
    --normal-fs: 0.938rem;
    --medium-fs: 0.813rem;
    --small-fs: 0.90rem;
    --tiny-fs: 0.625rem;
    --h1-fs: 1.5rem;
    --h2-fs: 1.25rem;
    --h3-fs: 1rem;
  }

}

/* main sudeshna's portfolio css starts here */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-fs);
}

/* body {
  background-color: var(--body-color);
  color: var(--text-color);
} */

body {
  min-height: 100vh;
  /* background-color: var(--background-color); */
  position: relative;
  top: 0;
  left: 0;
}

body::after {
  content: "";
  width: 125px;
  height: 125px;
  background-color: #C5C3E3;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  bottom: 84%;
  right: 92%;
}

body::before {
  content: "";
  width: 184px;
  height: 184px;
  background-color: #9DE3F3;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  bottom: 98%;
  right: 56%;
}

h1,
h2,
h3 {
  color: var(--title);
  font-weight: var(--bold-fw);
}

/* reusable class */

.section {
  padding: 3rem 0 2rem;
}

.section-title,
.section-title-sub {
  font-size: var(--h1-fs);
  color: var(--title-d);
}

.section-subtitle,
.section-subtitle-sub {
  display: block;
  font-size: var(--medium-fs);
  margin-bottom: var(--mb-0-25);
}

.section-title,
.section-subtitle {
  text-align: center;
}

.section-title-sub,
.section-subtitle-sub {
  text-align: start;
}

.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: inline-block;
  background-color: var(--title-dark);
  color: var(--white);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  font-weight: var(--medium-fw);
}

.button:hover {
  background-color: var(--title);
}

.button-icon {
  margin-left: var(--mb-0-5);
}

.button-flex {
  display: inline-flex;
  align-items: center;
}

.grid {
  display: grid;
  gap: var(--mb-1-5);
}

.partners-img-container {
  height: 100px;
  width: 100%;
  overflow: auto;
}

.partners-img-container::-webkit-scrollbar {
  width: 0 !important
}

/* hide scrollbar in Chrome */
.partners-img-container {
  scrollbar-width: none;
}

/* hide scrollbar in mozilla */
.partners-img-container {
  -ms-overflow-style: none;
}

/* hide scrollbar in IE */

.partners-img-container .partners-img {
  height: 100%;
  width: 200%;
  background: transparent url('./assets/expertise.png') repeat-x 50% 0;
  -webkit-animation: slideleft 5000s linear infinite;
  animation: slideleft 5000s linear infinite;
  cursor: grab;
}

/* responsive break points */

@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  /* .button {
    padding: 1rem 1.75rem;
  } */
}

@media screen and (max-width: 768px) {
  body {
    margin: 0 0 var(--nav-height) 0;
  }

  .section {
    padding: 2rem 0 4rem;
  }

  .section-subtitle {
    margin-bottom: var(--mb-0-75);
  }
}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 350px) {

  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1-5);
  }
}

/* @media screen and (max-width: 992px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 350px) {} */