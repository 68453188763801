.testimonials {
  background-color: #FFEFEF;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.testimonials-container {
  width: 750px;
  margin-top: 3rem;
}

.testimonial-card {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
}

.testimonial-img {
  width: 60px;
  border-radius: 3rem;
  margin-bottom: var(--mb-1);
}

.testimonial-name {
  font-size: var(--normal-fs);
  font-weight: var(--bold-fw);
  margin-bottom: var(--mb-0-25);
}

.testimonial-description {
  font-size: var(--medium-fs);
}

.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
  margin-top: 2rem;
}

.swiper-pagination-bullet-active {
  background-color: var(--title-dark) !important;
}

@media screen and (max-width: 992px) {
  .testimonials-container {
    width: initial;
  }

  .testimonial-card {
    padding: 1.25rem 1.5rem;
  }
}

.partners {
  padding: 2px;
}

@-webkit-keyframes slideleft {
  0% {
    background-position: 385000px;
  }

  100% {
    background-position: 0;
  }
}

@keyframes slideleft {
  0% {
    background-position: 385000px;
  }

  100% {
    background-position: 0;
  }
}