footer {
  width: 100%;
  height: max-content;
  padding: 4% 5% 0%;
  text-align: end;
  background-color: #DCDBEE;
  font-size: var(--small-fs);
}

.social-media-container {
  padding: 3.5rem 0 5rem;
}

.social-section-title {
  text-align: center;
  margin-bottom: var(--mb-2);
  color: var(--title-dark);
}

.section-footer-link {
  color: var(--title);
}

.section-footer-link:hover {
  color: var(--title-dark);
  font-weight: 600;
}

.section-link-list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-2);
}

.sm-link-list {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.sm-link {
  background-color: transparent;
  color: #FF77A5;
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-flex;
}

.sm-link:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #FF77A5;
}

@media screen and (max-width: 992px) {
  .sm-link {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  footer {
    height: 12vh;
    text-align: center;
    align-self: center;
    font-size: var(--tiny-fs);
  }
}

@media (min-width: 768px) {
  footer {
    clip-path: polygon(38% 48%, 64% 20%, 100% 0, 100% 100%, 0 100%, 0 58%);
  }
}