.expertise {
  padding-bottom: 2rem;
}

.expertise-container {
  width: 100%;
  padding: 35px 10%;
  padding-right: calc(10% - 30px);
  padding-top: 1rem;
}

main.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.col header.title {
  padding: 8px 0px 20px 30px;
  display: flex;
  align-items: center;
}

.col header .icon {
  margin-right: 1rem;
  font-size: 1.4rem;
}

.col header h2 {
  color: rgb(85, 85, 85);
  font-weight: 500;
  font-size: 1.25rem;
}

.col .contents {
  padding: 0px 30px;
  border-left: 2px solid #bababa;
}

.col .contents .box {
  position: relative;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 0.75rem;
  background-color: #ffffff;
  cursor: default;
  transition: all 0.4s;
  margin-bottom: 20px;
}

.col .contents .box:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #FF77A5;
}

.col .contents .box::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  right: calc(100% + 22px);
  top: 0;
  background-color: #FF77A5;
  border: 2px solid white;
}

/* .box h4 {
  position: relative;
  color: rgb(85, 85, 85);
  ;
  font-size: var(--tiny-fs);
} */

.box span {
  position: relative;
  color: rgb(85, 85, 85);
  ;
  font-size: var(--tiny-fs);
  cursor: pointer;
}

.box h4 {
  padding: 10px 0px 6px;
  color: #555;
}

.box p {
  line-height: 1.2;
  padding-bottom: 1rem;
  color: #555;
}

@media(max-width: 768px) {
  main.row {
    grid-template-columns: 1fr;
  }

  .row .col:nth-child(2) {
    margin-top: 30px;
  }
}