.home {
  padding-bottom: 4rem;
}

.home-content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 3rem;
  column-gap: 2rem;
  align-items: center;
}

.home-social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home-social-icon {
  font-size: 1.25rem;
  color: var(--title);
  transition: .4s;
}

.home-social-icon:hover {
  color: var(--title-dark);
  font-size: 2rem;
  font-weight: var(--bold-fw);
}

.home-title {
  font-size: var(--h1-fs);
  margin-bottom: var(--mb-0-25);
}

.home-female-icon {
  margin-right: 1rem;
  width: 38px;
  height: 38px;
}

.home-subtitle {
  position: relative;
  font-size: var(--normal-fs);
  padding-left: 6rem;
  font-weight: var(--normal-fs);
  margin-bottom: var(--mb-1);
}

.home-subtitle::before {
  content: '';
  position: absolute;
  width: 78px;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  top: 0.8rem;
}

.home-description {
  max-width: 400px;
  margin-top: 0.5rem;
  margin-bottom: var(--mb-2);
}

.home-img {
  background: url(../../assets/banner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  box-shadow: inset 0 0 0 9px rgb(255, 255, 255 / 30%);
  order: 1;
  justify-self: center;
  width: 500px;
  height: 500px;
  cursor: pointer;
}


@keyframes float-up-down {
  0% {
    -webkit-transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-2%);
    transform: translateY(-2%)
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}


@media (min-width: 768px) {
  .home-img {
    animation: float-up-down 2.5s ease-in-out infinite both;
  }
}

/* responsive break points */

@media screen and (max-width: 992px) {
  .home-content {
    grid-template-columns: 100px repeat(2, 1fr);
    column-gap: 1.25rem;
  }

  .home-information {
    text-align: center;
  }

  .home-female-icon {
    width: 26px;
    height: 26px;
  }

  .home-subtitle {
    padding-left: 1rem;
    /* margin-bottom: var(--mb-1); */
  }

  .home-subtitle::before {
    display: none;
  }

  /* .home-subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home-subtitle::before {
    width: 42px;
    top: 0.8rem;
  } */

  .home-description {
    max-width: initial;
    margin-bottom: var(--mb-2);
  }

  .home-img {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 768px) {
  .home {
    height: 100%;
  }

  .home-content {
    grid-template-columns: 0.5fr 3fr;
    /* padding-top: 3.5rem; */
  }

  .home-img {
    order: initial;
    justify-self: center;
  }

  .home-information {
    grid-column: 1/3;
  }

  .home-img {
    height: 450px;
    width: 450px;
  }
}

@media screen and (max-width: 576px) {

  .home-img {
    height: 400px;
    width: 400px;
  }

}

@media screen and (max-width: 480px) {

  .home-img {
    height: 350px;
    width: 350px;
  }

}

@media screen and (max-width: 430px) {

  .home-information {
    text-align: start;
  }

  .home-subtitle {
    padding-left: 3rem;
    /* margin-bottom: var(--mb-1); */
  }

  .home-subtitle::before {
    width: 28px;
    display: block;
  }

  .home-img {
    width: 300px;
    height: 300px;
  }

  .home-female-icon {
    width: 22px;
    height: 22px;
  }
}