.about {
  background-color: #FFEFEF;
  padding-bottom: 5rem;
}

.about-container {
  grid-template-columns: repeat(2, 1fr);
  padding-top: 3.5rem;
  align-items: center;
  column-gap: 4rem;
}

.about-img {
  width: 80%;
  border-radius: 1.5rem;
  justify-self: center;
  animation: profile-animate 4s ease-in-out infinite 1s;
}

@keyframes profile-animate {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.about-info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}

.about-container-box {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
  transition: box-shadow 0.3s ease;
  cursor: default;
}

.about-container-box:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.about-icon {
  font-size: 1.5rem;
  color: var(--title);
  margin-bottom: var(--mb-0-5);
}

.about-title {
  font-size: var(--medium-fs);
  font-weight: var(--bold-fw);
}

.about-subtitle {
  font-size: var(--tiny-fs);
}

.about-description {
  margin-bottom: var(--mb-2-25);
}

.button i {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

/* responsive break points */

@media screen and (max-width: 992px) {
  .about-container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }

  .about-img {
    width: 50%;
  }

  .about-container-box {
    padding: 0.75rem 0.5rem;
  }

  .about-data {
    text-align: center;
    padding-top: 2rem;
  }

  .about-info {
    justify-content: center;
  }

  .about-description {
    padding: 0 5 rem;
    margin-bottom: 2rem;
  }
}


@media screen and (max-width: 576px) {

  .about-img {
    width: 60%;
  }

  .about-info {
    grid-template-columns: repeat(3, 1fr);
  }

  .about-description {
    padding: 0;
  }
}

@media screen and (max-width: 350px) {

  .about-img {
    width: 80%;
  }

  .about-info {
    grid-template-columns: repeat(2, 1fr);
  }
}