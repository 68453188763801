.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: transparent;
}

.nav {
  height: calc(var(--nav-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav-logo,
.nav-toggle {
  color: var(--title);
  font-weight: var(--medium-fw);
}

.nav-list {
  display: flex;
  column-gap: 2rem;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--medium-fs);
  color: var(--title);
  font-weight: var(--medium-fw);
  transition: .3s;
}

.nav-icon,
.nav-close,
.nav-toggle {
  display: none;
}

/* Active */

.active,
.nav-link:hover {
  color: var(--title-dark);
  font-weight: 500;
}

/* responsive break points */
@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
    background-color: #fff !important;
    border-top: 1px solid #ddd;
  }

  .nav {
    height: calc(var(--nav-height) + 2rem);
  }

  .nav-menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem, 1.5rem 0 0;
    transition: .3s;
  }

  .show-menu {
    bottom: 0;
  }

  .nav-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav-icon {
    font-size: 1.2rem;
  }

  .nav-close {
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title);
  }

  .nav-close:hover {
    color: var(--title-color-dark);
  }

  .nav-toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav-icon,
  .nav-close,
  .nav-toggle {
    display: block;
  }
}

@media screen and (max-width: 350px) {
  .nav-menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav-list {
    column-gap: 0;
  }
}